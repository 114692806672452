import React from 'react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import DepartmentDetails from '../../components/DepartmentDetails';

const FaqPage = () => {
  return (
    <Layout pageTitle='Department of Radiation Oncology | LUTH'>
      <NavOne />
      <PageHeader title='Department of Radiation Oncology' />
      <DepartmentDetails title='Department of Radiation Oncology' />
      <Footer />
    </Layout>
  );
};

export default FaqPage;
